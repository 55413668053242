import {UserData} from "./Models";

const USER_EMAIL = 'userEmail'
const USER_ID = 'userId'
const TENANT_ID = 'accountId'

export function getCurrentUserId(): string {
	return localStorage.getItem(USER_ID) ?? ''
}

export function setCurrentUserId(value: string) {
	localStorage.setItem(USER_ID, value)
}

export function setCurrentUserEmail(email: string) {
	localStorage.setItem(USER_EMAIL, email)
}

export function getCurrentUserEmail() {
	return localStorage.getItem(USER_EMAIL)
}

export function getTenantId(): string {
	return localStorage.getItem(TENANT_ID) ?? ''
}

export function setTenantId(value: string) {
	localStorage.setItem(TENANT_ID, value)
}

export function isLoggedIn(): boolean {
	return !!getCurrentUserId()
}

export function clearSession() {
	setCurrentUserId('')
	localStorage.removeItem('token')
}
