import cardItem from 'assets/imgs/icons/card-item.png'
import visaLogo from 'assets/imgs/visa.svg'
import actionDots from 'assets/imgs/icons/action-dots.svg'
import { defaultToastOptions, formatCurrency, getCardArtClass, splitCardPan } from 'utils'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import iconActivate from 'assets/imgs/icons/activate-v2.svg'
import iconFreezeCard from '../../assets/imgs/icons/freeze.svg'
import iconReportStolen from '../../assets/imgs/icons/report-stolen.svg'
import iconCloseCard from '../../assets/imgs/icons/close-card.svg'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import ConfirmationDialog from './ConfirmationDialog'
import API from '../../api/API'
import { CircleSpinnerOverlay } from 'react-spinner-overlay'
import { toast } from 'react-toastify'
import onChange = toast.onChange

interface CardCardProps {
	id: string
	type: string
	number: string
	limit: number
	balance: number
	currency: string
	design: number
	cardId: string
	customName: string
	linkedUser?: string
	cardType: string
	status: string
}

interface DropDownMenuProps {
	show: boolean
	cardStatus: string
	cardId: string
	onStatusChanged?: () => void
}

enum DropDownAction {
	Freeze,
	Activate,
	Close,
	Stolen,
}

function DropDownMenu(props: DropDownMenuProps) {
	const [show, setShow] = useState(false)
	const [showConfirmation, setShowConfirmation] = useState(false)
	const [confirmationTitle, setConfirmationTitle] = useState('')
	const [confirmationBody, setConfirmationBody] = useState<ReactNode | undefined>()
	const [action, setAction] = useState<DropDownAction>()
	const [busy, setBusy] = useState(false)

	const dropdownRef = useRef<HTMLUListElement>(null)

	const onFreeze = () => {
		setAction(DropDownAction.Freeze)
		setConfirmationTitle('Are you sure you want to freeze this card?')
		setConfirmationBody(undefined)
		setShowConfirmation(true)
	}

	const onStolen = () => {
		setAction(DropDownAction.Stolen)
		setConfirmationTitle('Are you sure you want to report this card as stolen?')
		setConfirmationBody('This action is not reversible!')
		setShowConfirmation(true)
	}

	const onActivate = () => {
		setAction(DropDownAction.Activate)
		setConfirmationTitle('Are you sure you want to activate this card?')
		setShowConfirmation(true)
	}

	const onStatusChanged = () => {
		if (props.onStatusChanged) {
			props.onStatusChanged()
		}
	}

	useEffect(() => {
		function handleClickOutside(e: MouseEvent) {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node | null)) {
				setShow(false)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const freeze = () => {
		API.Cards.Freeze(props.cardId)
			.then((r) => {
				if (r.success) {
					onStatusChanged()
					toast.success(`Card '${props.cardId}' frozen successfully`, defaultToastOptions())
				} else {
					toast.error(r.error, defaultToastOptions())
				}
			})
			.finally(() => {
				setBusy(false)
			})
	}

	const unfreeze = () => {
		API.Cards.Unfreeze(props.cardId)
			.then((r) => {
				if (r.success) {
					onStatusChanged()
					toast.success(`Card '${props.cardId}' activated successfully`, defaultToastOptions())
				} else {
					toast.error(r.error, defaultToastOptions())
				}
			})
			.finally(() => {
				setBusy(false)
			})
	}

	const stolen = () => {
		API.Cards.ReportStolen(props.cardId)
			.then((r) => {
				if (r.success) {
					toast.success(`Card '${props.cardId}' reported successfully`, defaultToastOptions())
				} else {
					toast.error(r.error, defaultToastOptions())
				}
			})
			.finally(() => setBusy(false))
	}

	const onConfirmation = (value: boolean | null) => {
		setShowConfirmation(false)

		if (value) {
			//Do action
			setBusy(true)

			switch (action) {
				case DropDownAction.Freeze:
					freeze()
					break
				case DropDownAction.Activate:
					unfreeze()
					break
				case DropDownAction.Stolen:
					stolen()
					break
				default:
					setBusy(false)
			}
		}
	}

	return (
		<>
			{busy && <CircleSpinnerOverlay />}
			<div className="dropdown card-action-dropdown">
				<div className="card-action-container">
					<button
						className={'card-action' + (show ? ' show' : '')}
						type="button"
						//id="dropdownMenuButton1"
						// data-bs-toggle="dropdown"
						// aria-expanded="false"
						data-toggle="dropdown"
						aria-haspopup="true"
						onClick={() => setShow(!show)}
					>
						<img src={actionDots} alt="" />
					</button>
				</div>
				{show && (
					<ul
						className="dropdown-menu show dropdown-menu-end"
						data-popper-placement="bottom-start"
						ref={dropdownRef}
					>
						{props.cardStatus === 'Active' && (
							<li className="dropdown-item" onClick={onFreeze}>
								<img src={iconFreezeCard} alt="" />
								Freeze Card
							</li>
						)}
						{props.cardStatus === 'BlockedByCustomer' && (
							<li className="dropdown-item" onClick={onActivate}>
								<img src={iconActivate} alt="" />
								Activate
							</li>
						)}
						<li className="dropdown-item" onClick={onStolen}>
							<Link to="">
								<img src={iconReportStolen} alt="" />
								Report stolen/lost
							</Link>
						</li>
						{/*<li className="dropdown-item" onClick={onClose}>*/}
						{/*    <img src={iconCloseCard} alt=""/>*/}
						{/*    Close Card*/}
						{/*</li>*/}
					</ul>
				)}
			</div>
			{showConfirmation && (
				<ConfirmationDialog show={showConfirmation} onClose={onConfirmation} title={confirmationTitle}>
					{confirmationBody}
				</ConfirmationDialog>
			)}
		</>
	)
}

function CardCard(props: CardCardProps) {
	const statusClass = (status: string) => {
		if (status === 'Closed' || status === 'BlockedByCustomer') {
			return 'closed'
		}
		return ''
	}

	const [status, setStatus] = useState(props.status)

	const onStateChanged = () => {
		API.Cards.GetCards({ 'filters.id': props.id }).then((r) => {
			if (r.success && r.data && r.data.data.length > 0) {
				setStatus(r.data?.data[0].status ?? props.status)
			}
		})
	}

	return (
		<Col xl={3} lg={4} md={6} sm={12} className="cd-management">
			<div className="cd-holder">
				<Link
					to={`/card-management/${props.id}`}
					className={`cd card ${getCardArtClass(props.design)} d-flex flex-column `}
				>
					<div className="p-2 mb-auto">
						<div className="card-type">
							<div className="card-type-background">
								<img src={visaLogo} alt="" />
							</div>
						</div>
						<img src={cardItem} className="card-item-decor" alt="" />
					</div>
					<div className="p-2">
						<div className="card-number">{splitCardPan(props.number)}</div>
					</div>
					<div className="p-2">
						<div className="card-balance">{formatCurrency(props.balance, props.currency)}</div>
					</div>
				</Link>
				<ul className="cd-info cd-rows">
					<li>
						<div className="label">Custom name</div>
						<div className="value card-name">{props.customName}</div>
					</li>
					{props.linkedUser && (
						<li>
							<div className="label">Linked user</div>
							<div className="value">{props.linkedUser}</div>
						</li>
					)}
					<li>
						<div className="label">Card Type</div>
						<div className="value">{props.cardType.toUpperCase()}</div>
					</li>
					<li>
						<div className="label">Card ID</div>
						<div className="value">{props.cardId}</div>
					</li>
					<li>
						<div className="label">Spending limits</div>
						<div className="value">{formatCurrency(props.limit, props.currency)}</div>
					</li>
					<li>
						<div className="label">Status</div>
						<div className={`value card-status ${statusClass(status)}`}>{props.status}</div>
					</li>
				</ul>
				<DropDownMenu show={true} cardStatus={status} cardId={props.id} onStatusChanged={onStateChanged} />
			</div>
		</Col>
	)
}

export default CardCard
