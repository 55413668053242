import * as React from 'react'
import ContentRouter from 'components/ContentRouter'
import TopBar from 'components/elements/TopBar'
import Sidebar from 'components/elements/Sidebar'
import { ToastContainer } from 'react-toastify'
import {useEffect} from "react";
import {getCurrentUserId} from "../api/Session";

export function Layout() {


	return (
		<>
			<TopBar />
			<Sidebar />
			<ContentRouter />
			<ToastContainer />
		</>
	)
}
