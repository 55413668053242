import React, { MouseEvent, useEffect, useState } from 'react'
import { animated, config, useSpring } from '@react-spring/web'
import { InternalModalProps } from './modals/Modal'
import { Button, Col, Container, Row } from 'react-bootstrap'

export interface ConfirmationDialogProps extends React.HTMLAttributes<HTMLDivElement> {
	onClose?: (value: boolean | null) => void
	title?: string
	show: boolean
}

function ConfirmationDialog(props: ConfirmationDialogProps) {
	const [show, setShow] = useState(props.show)

	useEffect(() => {
		setShow(props.show)
	}, [props.show])

	const transformChangeValues = (show: boolean) => {
		return show ? 'translate(-50%, -50%)' : 'translate(-50%, -40%)'
	}

	const propsModal = useSpring({
		from: { transform: transformChangeValues(!show) }, //, opacity: OpacityChangeValues(!show)},
		to: { transform: transformChangeValues(show) }, //, opacity: OpacityChangeValues(show)},
		config: { ...config.wobbly },
	})

	const closeModal = (value: boolean | null) => {
		if (props.onClose) {
			props.onClose(value)
		}
	}

	const stopPropagation = (e: MouseEvent) => {
		e.stopPropagation()
	}

	return (
		<div {...props} prefix={'eps'} className="modal confirmation-dialog" onClick={() => closeModal(null)}>
			<animated.div className="modal-selector" style={propsModal} onClick={stopPropagation}>
				<Container className="container" onClick={(event) => event.stopPropagation()}>
					<Row className={'justify-content-center'}>
						{ !!props.title && <Col><h2 className={"align-content-center title"}>{props.title}</h2></Col> }
					</Row>
					<Row className={'justify-content-center'}>{props.children}</Row>
					<hr/>
					<Row className={'justify-content-between'}>
						<Col>
							<Button variant={'success'} onClick={() => closeModal(true)}>
								Yes
							</Button>
						</Col>
						<Col className={'align-content-end'}>
							<Button variant={'danger'} onClick={() => closeModal(false)}>
								No
							</Button>
						</Col>
					</Row>
				</Container>
			</animated.div>
		</div>
	)
}

export default ConfirmationDialog
