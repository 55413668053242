//import Api from "api/Api";
//import notificationIcon from "assets/imgs/icons/notification.svg"
import { clearSession, getCurrentUserEmail, getCurrentUserId } from '../../api/Session'
import { Container, Row } from 'react-bootstrap'
import { PropsWithChildren, useState } from 'react'
import companyLogo from 'assets/imgs/company-logo.svg'

function TopBar() {
	let timeout: number | undefined
	const [active, setActive] = useState(false)

	const onClickLogout = (e: React.MouseEvent) => {
		//e.preventDefault();
		console.log('Logout clicked')
		clearSession()
	}

	const showTip = () => {
		console.log('OnEnter')
		timeout = window.setTimeout(() => {
			setActive(true)
		}, 399)
	}

	const hideTip = () => {
		console.log('OnLeave')
		clearInterval(timeout)
		setActive(false)
	}

	return (
		<header className="topbar">
			<Container fluid={true}>
				<Row className="align-items-center row-cols-auto justify-content-end">
					<a href="/" className="col" onClick={onClickLogout} onMouseEnter={showTip} onMouseLeave={hideTip}>
						<div className="user-bar">
							<img src={companyLogo} alt="Company Logo" />
							<Letter>{getCurrentUserId().charAt(0)}</Letter>
							{active && (
								<div className={'user-tooltip'}>
									<span className={'user-name'}>
										{getCurrentUserEmail()}
									</span>
								</div>
							)}
						</div>
					</a>
				</Row>
			</Container>
		</header>
	)
}

function Letter(props: PropsWithChildren) {
	return <div className="letter">{props.children}</div>
}

export default TopBar
