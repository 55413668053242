import API from 'api/API'
import React, { useEffect, useState } from 'react'
import { CardData, CardStatus } from 'api/Models'
import CardCard from 'components/elements/CardCard'
import BasePage from 'components/pages/BasePage'
import { RiseLoader } from 'react-spinners'
import PageHeader from '../elements/PageHeader'
import { Col } from 'react-bootstrap'
import { newCardRoute } from '../../Routes'
import LinkButton from '../elements/LinkButton'
import creditCardPlus from '../../assets/imgs/icons/credit-card-plus.svg'


export interface CardManagementPageProps {}

function HeaderControls() {
	return (
		<>
			<Col className="me-auto"></Col>
			<Col>
				<div className="page-nav">
					<LinkButton to={newCardRoute} icon={creditCardPlus} label="New Card" type="primary" />
				</div>
			</Col>
		</>
	)
}


function CardManagementPage(props: CardManagementPageProps) {
	const [cards, setCards] = useState<CardData[] | null>(null)

	useEffect(() => {
		API.Cards.GetCards({ withBalance: true }).then((r) => {
			setCards(r.data!.data)
		})
	}, [])

	const headerControl = <HeaderControls />
	return cards === null ? (
		<>
			<PageHeader title={'Card Management'} controls={headerControl} />
			<BasePage className="cd-management">
				<RiseLoader size={20} />
			</BasePage>
		</>
	) : (
		<>
			<PageHeader title={'Card Management'} controls={headerControl} />
			<BasePage className="cd-management">
				{cards.map((c) => (
					<CardCard
						id={c.id ?? ''}
						cardId={c.cardId ?? ''}
						design={c.cardDesign ?? 0}
						type={c.type ?? 'Unknown'}
						number={c.processorData?.obscuredPan ?? ''}
						customName={c.processorData?.embossName ?? ''}
						balance={c.balance ?? 0}
						currency={c.currency ?? 'USD'}
						status={c.status ?? 'Unknown'}
						limit={c.creditLimit ?? 0}
						linkedUser={c.userId ?? "Unknown"}
						cardType={c.type ?? "Unknown"}
					/>
				))}
			</BasePage>
		</>
	)
}

export default CardManagementPage
